import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactTable from 'react-table';
import Popup from 'reactjs-popup';
import 'reactjs-popup/';

import AuthenticatedPage from '../../../Components/AuthenticatedPage';
import { clearJobs, getJobs, searchJobs, submitJobs } from '../../../state/vehicle_firmware';
import { DateTimePicker } from 'react-widgets';
import momentLocalizer from 'react-widgets-moment';

import './index.scss';

momentLocalizer();

const FirmwareUpdates = ({ actions, vehicleFirmware }) => {
  const [vehicles, setVehicles] = useState('');
  const [vehicleArray, setVehicleArray] = useState([]);
  const [requestDate, setRequestDate] = useState();
  const [showResetConfirmation, setShowResetConfirmation] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const selectTable = useRef();

  useEffect(() => {
    const currentArray = vehicles
      .replaceAll(' ', '')
      .split(',')
      .filter((v) => v.length === 4);
    setVehicleArray(currentArray);
    setSubmitDisabled(currentArray.length === 0);
  }, [vehicles]);

  const onSubmitVehicles = (event) => {
    event.preventDefault();
    if (requestDate) {
      actions.searchJobs(vehicleArray, requestDate);
    } else {
      actions.submitJobs(vehicleArray, false);
    }
  };

  const onChange = (event) => setVehicles(event.target.value);

  const onChangeStart = (requestDate) => setRequestDate(requestDate);

  const onConfirmReset = (event) => {
    event.preventDefault();
    setVehicles('');
    setVehicleArray([]);
    setShowResetConfirmation(false);
    setRequestDate();
    setSubmitDisabled(true);
    actions.clearJobs();
  };

  const onClickReset = (event) => {
    event.preventDefault();
    setShowResetConfirmation(true);
  };

  const onClosePopup = () => setShowResetConfirmation(false);

  const onSubmitRefreshJobs = (event) => {
    event.preventDefault();
    const { startedAt } = vehicleFirmware;
    actions.getJobs(startedAt);
  };

  const vehicleSubmissionForm = (vehicles) => {
    return (
      <Fragment>
        <p>
          Enter the names of scooters to update in the field below. Separate scooter names with a
          comma (e.g. &lsquo;ABCD, EFGH&rsquo;).
        </p>
        <form className="firmwareForm" onSubmit={onSubmitVehicles}>
          <label htmlFor="vehicles">Vehicles to update</label>
          <textarea
            className="firmwareForm__textarea"
            rows={5}
            cols={60}
            name="vehicles"
            placeholder="ABCD, EFGH"
            value={vehicles}
            onChange={onChange}
          />
          <label htmlFor="startTime">
            If you want to look up previous firmware requests for these vehicles, set the date/time
            to the approximate time when the original update requests were submitted.{' '}
            <strong>Leave this blank to submit new update requests.</strong>
          </label>
          <DateTimePicker name="startTime" onChange={onChangeStart} value={requestDate} />
          <div className="form_input_section">
            <button type="submit" disabled={submitDisabled}>
              Submit
            </button>
            <button onClick={onClickReset}>Reset</button>
          </div>
        </form>
      </Fragment>
    );
  };

  const firmwareUpdateTable = () => {
    const data =
      vehicleArray.length > 0
        ? vehicleFirmware.jobs.filter(({ vehicle_name }) => vehicleArray.includes(vehicle_name))
        : vehicleFirmware.jobs;
    return (
      <div className="firmwareStatusTable">
        <p>{`Firmware updates from ${vehicleFirmware.startedAt}`}</p>
        <form onSubmit={onSubmitRefreshJobs}>
          <button type="submit">Refresh Jobs</button>
          <button onClick={onClickReset}>Reset</button>
        </form>
        <ReactTable
          ref={selectTable}
          filterable
          className="-striped -highlight"
          data={data}
          columns={[
            {
              Header: 'Name',
              accessor: 'vehicle_name',
              maxWidth: 60,
            },
            {
              Header: 'State',
              accessor: 'state',
              maxWidth: 100,
            },
            {
              Header: 'Status',
              accessor: 'status',
              maxWidth: 400,
            },
            {
              Header: 'Firmware',
              columns: [
                {
                  Header: 'binary',
                  accessor: 'firmware_name',
                  maxWidth: 150,
                },
                {
                  Header: 'starting',
                  accessor: 'starting_firmware_version',
                  maxWidth: 100,
                },
                {
                  Header: 'ending',
                  accessor: 'ending_firmware_version',
                  maxWidth: 100,
                },
                {
                  Header: 'Success',
                  id: 'success',
                  accessor: (row) => {
                    const {
                      ending_firmware_version,
                      firmware_name,
                      starting_firmware_version,
                      state,
                      status,
                    } = row;
                    if (state === 'running') {
                      return;
                    }
                    const fwLocation = firmware_name.search(ending_firmware_version);
                    const needsFlashing =
                      status === 'Finished Successfully' &&
                      fwLocation === -1 &&
                      starting_firmware_version === ending_firmware_version;
                    if (needsFlashing) {
                      return 'Cannot be updated OTA';
                    }
                    return fwLocation > -1 ? 'Yes' : 'No';
                  },
                },
              ],
            },
          ]}
        />
      </div>
    );
  };

  return (
    <AuthenticatedPage>
      <div className="page-title">Vehicle Firmware Updates</div>
      {vehicleFirmware.vehicles && vehicleFirmware.vehicles.length === 0
        ? vehicleSubmissionForm(vehicles)
        : firmwareUpdateTable()}
      <Popup
        open={showResetConfirmation}
        closeOnDocumentClick={true}
        onClose={onClosePopup}
        overlayStyle={{ backgroundColor: 'light-grey' }}
        contentStyle={{ maxHeight: '100vh', overflowY: 'auto' }}>
        <div className="confirmReset">
          <p>Reset form?</p>
          <form onSubmit={onConfirmReset}>
            <button type="submit">Yes</button>
            <button type="submit" onClick={onClosePopup}>
              No
            </button>
          </form>
        </div>
      </Popup>
    </AuthenticatedPage>
  );
};

FirmwareUpdates.propTypes = {
  actions: PropTypes.object,
  vehicleFirmware: PropTypes.object,
};

const mapStateToProps = ({ vehicle_firmware: vehicleFirmware }) => {
  return { vehicleFirmware };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ clearJobs, getJobs, searchJobs, submitJobs }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FirmwareUpdates);
